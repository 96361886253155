import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Internals
import { Footer, Header } from './components/common';
import { Home } from './pages';
import './App.css';
import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
import { motion, useScroll, useSpring } from "framer-motion";

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.13.1/cdn/');

function App() {
	const { scrollYProgress } = useScroll()
  const scaleX = useSpring(scrollYProgress)

  return (
    <div className="App">
      <motion.div className="progress-bar" style={{ scaleX }} />
      <Header/>
      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
