import React from 'react';
import { useInView } from 'react-intersection-observer';
import { TypeAnimation } from 'react-type-animation';
import Son from '../../assets/son.png';

import './hero.styles.css';

const Hero = () => {
  const { ref: greetRef, inView: greetView } = useInView({ threshold: 0, });
  const { ref: nameRef, inView: nameView } = useInView({ threshold: 0, });
  const { ref: actionRef, inView: actionView } = useInView({ threshold: 0, });
  
  const HandlerClick = (id: string) => {
		const scrollTargetElement = document.getElementById(id)

		if (scrollTargetElement) {
				scrollTargetElement.scrollIntoView({ behavior: 'smooth' });
		}
	}

  return (
    <div className='hero-body'>
      <h1
        className={`${greetView ? "trans-from-left" : ""}`}
        ref={greetRef}
      >
          <img className='son-image' src={Son} alt="" />
        <span style={{ color: 'rgb(249, 203, 111)' }}>
          Greetings</span>, world!
        <div className="son-football-container">
        </div>
      </h1>

      <h1 className={`${nameView ? "trans-from-right" : ""}`} ref={nameRef}>
        I'm Siddhant Prateek Mahanayak
      </h1>

      <div className="role-typer">
        <TypeAnimation
        sequence={[
          'Software Engineer',
          1000, 
          'Backend Engineer', 
          2000, 
          'DevOps Engineer', 
          () => {
            console.log('');
          },
        ]}
        wrapper="h1"
        cursor={true}
        repeat={Infinity}
        style={{ fontSize: '2.5rem', display: 'inline-block', color: "rgb(16, 122, 140)" }}
      />
      </div>
      <div className={`other-contents ${actionView ? "trans-from-left" : ""}`}
        ref={actionRef}
      >
        <p>Want to Learn More</p>
        <div className="hero-action-btn">
          <div className="abt-btn ot-btn">
            <p onClick={() => HandlerClick("about-me")}>About Me.</p>
          </div>
          <div className="yellow-btn  ot-btn">
            <p>Resume</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero