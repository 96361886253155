import React from 'react'
// Internal
import './experience.styles.css';
import { IEXP } from '../../constants/experience';
import SlDetails from '@shoelace-style/shoelace/dist/react/details';

const Experience = (props: IEXP ) => {
  return (
    <div className='experience-card'>
      <SlDetails summary={"◉  " + props.company_name + " - " + props.role} open={false}>
          <h3 className='company-header'>
              {/* <img className='exp-compnany-url' src={"https://img.icons8.com/ios-filled/50/business.png"} alt="business"/> */}
             {/* <img className='exp-compnany-url' src={props.url} alt="" /> */}
             {props.company_name}
          </h3>
          <p>{props.role} • <i>{props.employment}</i></p>
          <p>{props.time}</p>
          <p className='comp-exp' style={{ textAlign: 'justify'}}>
            <div dangerouslySetInnerHTML={{ __html: props.experience }} />
          </p>
      </SlDetails>
    </div>
  )
}

export default Experience;