import React from 'react'
import './footer.styles.css';
import { None } from 'framer-motion';

interface IFOOTERNAV {
  itemName?: string
  link: string
  icon: string
}

let FOOTER_ITEM_LIST: IFOOTERNAV[] = [
  {
    itemName: 'Github',
    icon: "https://img.icons8.com/ios-filled/100/github.png",
    link: "https://github.com/siddhantprateek",
  },
  {
    itemName: "Linkedin",
    icon: "https://img.icons8.com/ios-filled/100/linkedin.png",
    link: "https://www.linkedin.com/in/siddhantprateek/", 
  },
  {
    itemName: "TwitterX",
    icon: "https://img.icons8.com/ios-filled/100/twitterx--v1.png",
    link: "https://x.com/siddhantprateek",
  }
]

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className="left-footer-container">
      © All copyright reserved.  2024 - Made by Siddhant Prateek Mahanayak
      </div>
      <div className="right-footer-container">
        <ul className='footer-nav-item'>
          {FOOTER_ITEM_LIST.map((item: IFOOTERNAV) => (
            <li className='footer-item'>
              <a href={item.link}>
                <img src={item.icon} alt="" />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Footer