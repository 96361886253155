// External
import React from 'react';

// Internal
import './header.styles.css';
import { LOGO } from '../../../assets';

const Header = () => {

	const HandlerClick = (id: string) => {
		const scrollTargetElement = document.getElementById(id)

		if (scrollTargetElement) {
			scrollTargetElement.scrollIntoView({ behavior: 'smooth' });
		}
	}
	return (
		<header className='header trans-from-top'>

			<div className="left-header">
				<img src={LOGO} className='header-logo' alt="" />
			</div>

			<div className="right-header">
				<ul>
					<li onClick={() => HandlerClick("about-me")}>About Me</li>
					<li onClick={() => HandlerClick("experience")}>Experience</li>
					<li onClick={() => HandlerClick("skills")}>Skills</li>
					<li onClick={() => HandlerClick("projects")}>Projects</li>
					<li onClick={() => HandlerClick("blogs")}>Blog</li>
					<li>
						<div className="yellow-btn" onClick={() => HandlerClick("connect")}>
							<p>Get In Touch</p>
						</div>
					</li>
				</ul>


			</div>
			<div className="mobile-right-header">
				<div className="yellow-btn">
					<p>Get In Touch</p>
				</div>
			</div>
		</header>
	)
}

export default Header;