// External
import React, { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer';
// Internals
import { Hero, Featured, About, Publication, Hacks, Sponsor, Connect } from '../../components';
import TECH_STACK from '../../constants/stack.json';
// styles
import './home.styles.css';
import { HighLights, Skill } from '../../components/common';
import Experience from '../../components/experience/experience.component';
import { EXPERIENCES, IEXP } from '../../constants/experience';
import { motion } from "framer-motion";
import { marqueeVariants } from '../../components/marquee';
import { sanityClient }  from '../../integration/sanity';
import Messi from '../../assets/messi.png';
import { BOLT, UPICON } from '../../assets';
import HighlightedData from '../../components/common/highlights/highlights.json';
const Home = () => {
	const { ref: techStackRef, inView: techStackView } = useInView({ threshold: 0, });
	const { ref: hackStackRef, inView: hackView } = useInView({ threshold: 0, });
	const { ref: pubRef, inView: pubView } = useInView({ threshold: 0, });

	useEffect(() => {
		sanityClient.fetch(
			`*[_type == "project"]{
				title,
				slug,
				brief,
				projectImage
			}`
		)
		.then((data) => console.log(data))
		.catch(console.error);
	}, [])


	
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

	return (
		<div className='home-page'>
			<div className="hero-section">
				<Hero />
			</div>
			<div className="mrq-highlights">
				<div className='marquee highlighted-hero-marquee'>

					<motion.div
						className="track"
						variants={marqueeVariants}
						animate="animate"
					>
						{HighlightedData.map((data) => (
							<HighLights 
								title={data.title} 
								url={data.url} 
								target={data.target} 
								key={data.id} />
						))}
					</motion.div>


				</div>
			</div>

			{showButton && (

				<div className="scroll-to-top" onClick={scrollToTop}>
					<img className='up-icon' src={UPICON} alt="" />
				</div>
      )}

			<div className="featured-section">
				<Featured />
			</div>

			<div className="about-me-section">
				<About />
			</div>

			<div className="pub-section-header trans-from-left">
				<h1 className={`${pubView ? "tracking-in-expand" : ""}`}>PUBLICATIONS</h1>
			</div>
			<div className="publication-section" ref={pubRef}>
				<Publication />
			</div>

			<div className="skill-section">
				<div className="skill-container" id='skills'>
					<div className={`skill-header ${techStackView ? "trans-from-left" : ""}`}
						ref={techStackRef}
					>
						<h1>TECH STACK</h1>
						<p>These are the tools I bring to the battlefield.</p>
					</div>

					<div className="skill-tabs">
						<div className="vertical-marquee">
							{TECH_STACK.slice(1, 10).map((tech) => (
								<Skill
									title={tech.tech_name}
									url={tech.img}
								/>
							))}
						</div>
						<div className="vertical-marquee-reverse">
							{TECH_STACK.slice(10, 20).map((tech) => (
								<Skill
									title={tech.tech_name}
									url={tech.img}
								/>
							))}
						</div>

						<div className="vertical-marquee">
							{TECH_STACK.slice(20, 30).map((tech) => (
								<Skill
									title={tech.tech_name}
									url={tech.img}
								/>
							))}
						</div>
					</div>
				</div>
			</div>

			<div className="hackathon-header" >
				<h1 className={`${hackView ? "tracking-in-expand" : ""}`} >Hackathons <span className='red-dot'>.</span></h1>
			</div>
			<div className="hackathon-section">
				<div className="hack-containers" ref={hackStackRef}>
					<Hacks />
				</div>
			</div>

			{/* TEXT MARQUEE */}
			<div>
				<div className="marquee">
					<motion.div
						className="track"
						variants={marqueeVariants}
						animate="animate"
					>
						<h1>
							Let's Work Together. Let's Work Together. Let's Work Together. Let's
							Work Together. Let's Work Together. Let's Work Together. Let's Work
							Together
						</h1>
					</motion.div>
				</div>
			</div>


			<div className="experience-header" id='experience'>
				<h1>Experience</h1>
			</div>
			<div className="experience-section-container">
				{EXPERIENCES.map((exp: IEXP) => (
					<Experience {...exp} />
				))}
			</div>

			{/* <div className="projects-header">
				<h1>Projects</h1>
			</div>
			<div className="project-section">
			</div> */}

			{/* TODO */}
			{/* <div className="blog-header" id="blogs">
				<h1>Blogs</h1>
			</div>
			<div className="blogs-section">
			</div>
			 */}
			<div className="football-ground-section">
				<div className="post"></div>
				<img className='bolt-img' src={BOLT} alt="" />
			 	<img className='messi-img' src={Messi} alt="" />
			</div>
				
			<div className="connect-background" id='connect'>
				<h1>Connect</h1>
			</div>
			<div className="connect-with-sections">
				<div className="get-in-touch-container">
					<Sponsor />
					<Connect />
				</div>
			</div>



		</div>
	)
}

export default Home;