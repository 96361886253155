import React from 'react'
import { useInView } from 'react-intersection-observer';

// Internals
import './publication.styles.css';
import { PAPER1 } from '../../assets';

let content = "In this paper, an end-to-end secure, anonymous, anti-quantum, tamper-resistant e-voting system has been proposed using blockchain-based decentralized architecture. The available key generation and key exchange algorithms have been analyzed based on different parameters. Currently, almost all models are prone to quantum type of attacks. The proposed methodology implements the concepts of quantum cryptography, thus preventing these types of attacks. After the registration and authentication phases, voters cast votes which are then encrypted using Elliptical Curve-Diffie–Hellman key agreement protocol. Smart contracts and some libraries are used for automating the execution. These contracts are deployed over Polygon network that uses a modified proof-of-stake consensus mechanism. Polygon has the ability to process the transactions at a faster rate and also has lower transaction fees compared to Ethereum. InterPlanetary File system (IPFS) which is a more reliable and decentralized alternative to cloud storage system has been used. It also provides security benefits and makes it impossible for the third party to access the voting information."


const Publication = () => {
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	});

	return (
		<div className='publication-container'>
			<div className="pub-img largeGaps">
				<img src={PAPER1} className='publication-img' alt="" />
			</div>
			<div ref={ref} className={`publication-header ${inView ? "trans-from-right": ""}`}>
				<h1>
					Enhancing E-Voting Security with Quantum-Resistant Encryption: A Blockchain-Based
					Approach Utilizing Elliptic Curve Diffie-Hellman and Decentralized Storage
				</h1>
				<h3 style={{ color: 'rgb(249, 203, 111)'}}>Abstract</h3>
				<p>
					{content.slice(0, 320)}...
				</p>
				<div className="yellow-btn read-article">
          <a href='https://link.springer.com/article/10.1007/s42979-023-02041-3' target='__blank'>Read about it.</a>
        </div>
			</div>
		
		</div>
	)
}

export default Publication