import React from 'react'

// Internal 
import './skill.styles.css';

const Skill = (props: { title: string, url: string }) => {
  return (
    <div className="skill item">
        <img src={props.url} className='skill-item-img' alt="" />
        <h3 className='skill-title'>{props.title}</h3>
        {/* <p className='skill-category'>Cloud</p> */}
    </div>
  )
}

export default Skill