export interface IEXP {
  company_name: string;
  role: string;
  time: string;
  url: string;
  employment: string;
  experience: string;
}

export const EXPERIENCES: IEXP[] = [
  {
    company_name: "Ai Planet",
    role: "MLOps / DevOps Engineer",
    time: "March 2024 – Present",
    url: "",
    employment: "Remote, IN",
    experience: `
    <ul>
      <li>Incorporated the Keda-http Addon for dynamic scaling based on HTTP traffic as a component of deployment worker.</li>
      <li>Implemented a Kubernetes Job to adjust the ScaledObject and HTTPScaledObject for scaling down to zero during periods of low traffic.</li>
      <li>Implemented Kubernetes Job to add triggers to Keda ScaledObject to scale based on Memory, Traffic, CPU Utilization and conducted load testing with K6 by incrementally scaling virtual users to assess system resilience through HTTP requests.</li>
      <li>Worked on Cloud Migration using AWS Application Migration Service, AWS Data Migration Service.</li>
      <li>Implemented MinIO Object Storage setup for Kubernetes on an EKS Cluster, along with configuring several other Kubernetes operators such as New Relic, Kube-prometheus-operator, and Keda using charts.</li>
      <li>Implemented budget notifications and optimized the utilization of infrastructure resources, leading to a 21% reduction in infrastructure expenses. This involved selecting appropriate instance types and volumes, resulting in monthly savings of approximately $400.</li>
      <li>Collaborated with the team lead to ensure the smooth migration and testing of Kubernetes clusters, instances, and databases, minimizing downtime.</li>
      <li>Developed pipelines using ArgoCD and workflows with GitHub Actions, resulting in a 30% reduction in deployment times and increase in workflow efficiency.</li>
    </ul>
    `,
  },
  {
    company_name: "TravelArrow",
    role: "Full-Stack / DevOps Engineer",
    time: "May 2024 – Jun 2024",
    url: "",
    employment: "Contract Remote, IN",
    experience: `
    <ul>
      <li>Implemented and managed a robust self-hosted MongoDB replica set on Azure VM instances, ensuring high availability, data redundancy, and enhanced security through authentication and TLS/SSL encryption.</li>
    </ul>
    `,
  },
  {
    company_name: "DivineChants",
    role: "Software Developer",
    time: "December 2023 - April 2024",
    url: "https://media.licdn.com/dms/image/D4D0BAQFXYRhdWzIfTg/company-logo_100_100/0/1702388828404?e=1714608000&v=beta&t=7ib2a0BJ8UUBf0S8aIsKwuf7DJfWqOczzdIwCae5hEU",
    employment: "Full Time",
    experience: `
    <ul>
      <li>Architected a serverless music platform with a focus on achieving peak performance and scalability.</li>
      <li>Developed a multi-cloud solution by integrating MongoDB with S3/Cloud Storage, managing and retrieving user data and music files across platforms.</li>
      <li>Developed interactive music player and enhanced application flow with state management.</li>
      <li>Created a docker-compose configuration file to orchestrate multi-container environments, streamlining the local debugging and testing of applications.</li>
      <li>Led the development of over 100+ RESTful endpoints for the music platform, strictly adhering to the MVC API architectural pattern to improve application scalability and maintainability.</li>
      <li>Created a script to automate the deployment of Jenkins server on AWS EC2 instances.</li>
      <li>Deployed MVP server on AWS EC2 and configured Nginx as a reverse proxy to proxy requests.</li>
      <li>Designed and developed responsive web-based components using React.</li>
      <li>Implemented several base features for the music platform and admin management platform.</li>
      <li>Designed and implemented a user-friendly admin dashboard for effective soundtrack and artist profile management using React.</li>
      <li>Developed numerous base functionalities for the music platform and the administrative management system.</li>
      <li>Implemented lazy loading for components, ensuring that resources were loaded only when needed, reducing initial page load times and improving overall performance.</li>
   </ul>
    `,
  },

  {
    company_name: "The Linux Foundation - Hyperledger CLP",
    role: "LFX Mentee",
    time: "July 2023 - December 2023",
    url: "https://media.licdn.com/dms/image/C560BAQEXYSSWEvEL9g/company-logo_100_100/0/1657565842240/the_linux_foundation_logo?e=1714608000&v=beta&t=HrMJuefkcbBkxUgfaU9Jxs80ZlmpeodOwxRVNvCCbgc",
    employment: "Internship",
    experience: `
    <ul>
      <li>Architect scalable Go-based consensus protocol for HyperLedger Fabric, improving performance and reliability.</li>
      <li>Analyze various consensus protocols such as Raft, PBFT, mirBFT, BFT-Smart, and zyzzyva.</li>
      <li>Developed automated testing environment for the consensus algorithm over AWS EC2 instances using Terraform.</li>
      <li>Conducting thorough testing and assessment of different protocols to extract key system requisites, enabling the creation of a new consensus protocol that increased throughput by 30% while ensuring data integrity.</li>
    </ul>
    `,
  },
  {
    company_name: "Abblor Tech",
    role: "Full Stack Developer",
    time: "April 2023 - July 2023",
    url: "https://media.licdn.com/dms/image/D560BAQEG0BrjSjsIxA/company-logo_100_100/0/1702018790795/abblor_logo?e=1714608000&v=beta&t=WOev4jKtjQt81ndHDJin5oBuZMQcVAuDxvvbs53AT2o",
    employment: "Internship",
    experience: `<ul>
      <li>Responsible to design a highly scalable system and database framework for the Login-as-a-Service application, including the creation of a sequence diagram to visualize the system’s interactions.</li> \
      <li>Led the implementation of application microservices in Go, resulting in a 30% improvement in performance and ensuring seamless compatibility across platforms. Utilized gRPC for efficient internal network communication between services and developed a prototype using Node.js and Firestore to validate system scalability.</li> \
      <li>Implemented an optimized session snapshot management solution using in-memory caching, resulting in a 75% reduction in response time.</li> \
      <li>Implemented client-side caching across websites using React, resulting in a 50% reduction in loading time and significantly enhancing user experience by minimizing latency and network queries.</li>
      <li>Deployed Pi-Hole on EC2 instance to intercept and block DNS-level ad-requests, enhancing browsing speed and reducing user data consumption.</li>
      <l1>Implemented socket functionality to send real-time server updates to client browser during the authentication process.</li>
      <li>Automated the deployment process of a Node.js application server on Google Cloud Platform (GCP) by developing Bash scripts, streamlining the deployment process and minimizing developer effort.</li>
      <li>Designed API using Swagger to document the entire workflow of the application, ensuring clear understanding of installation, deployment, and scripting procedures.</li>
    </ul>`,
  },
];
