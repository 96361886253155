import React from "react";
// 1. Importing framer-motion
import { motion } from "framer-motion";

import "./marquee.css";

export const marqueeVariants = {
  animate: {
    x: [0, -685],
    transition: {
      x: {

        repeat: Infinity,
        repeatType: "loop",
        duration: 10,
        ease: "linear",
      },
    },
  },
};
