import React from 'react';
import './sponsor.styles.css';

const Sponsor = () => {
  return (
    <div className='sponsor-container' id='lets-talk'>
      <h1>Sponsor</h1>
      <p className='sponsor-text'>
        If you dig my project or enjoy my work especially my blog!, consider sponsoring me. It helps me keep creating awesome content!</p>

      <button className='sponsor-me-btn'>
        Sponsor Me
      </button>
    </div>
  )
}

export default Sponsor