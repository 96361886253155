import React from 'react'
import './connect.styles.css';

const Connect = () => {
  return (
    <div className='contact-container'>
      <h1>Let's talk!</h1>
      <p>Shoot me an email at hi@siddhantprateek.xyz.</p>
    </div>
  )
}

export default Connect