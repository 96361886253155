import React from 'react';
import { useInView } from 'react-intersection-observer';

// Internals
import { PROFILE } from '../../assets';
import './about.styles.css';

const About = () => {
	const { ref, inView } = useInView({
		threshold: 0,
	});

	const HandlerClick = (id: string) => {
		const scrollTargetElement = document.getElementById(id)

		if (scrollTargetElement) {
				scrollTargetElement.scrollIntoView({ behavior: 'smooth' });
		}
	}

	return (
		<div className='about-me' id='about-me'>
			<div className="about-content" >
				<h1 >ABOUT ME <span className='ab-dot'>.</span></h1>
				<p className={`${inView ? "trans-from-left" : ""}`} ref={ref}>
					👋 Hi! you can call me Siddhant, a Computer Science Graduate. My passion lies in exploring the fascinating worlds of Cloud Native.
					These days though, my work is more about the backend. But honestly, I'd rather be seen as a jack-of-all-trades coder, someone who's comfy 
					with both frontend and all sorts of other things. If I have some spare time after all that, I love contributing to open-source projects. It's like giving back to the coding community!
				</p>
				<div className="yellow-btn lets-connect">
          <p onClick={() => HandlerClick("lets-talk")}>Let's Talk</p>
        </div>
			</div>
			<div className={`img-frame ${inView ? "trans-from-right": ""}`} ref={ref}>
				<img className='profile-img' src={PROFILE} alt="" />
			</div>
		</div>
	)
}

export default About