import React from 'react'

// Externals
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

// Internals
import './featured.styles.css';
import { AWS_SAA, CKA, CKAD } from '../../assets';

interface MetricModel {
	start: number
	end: number
	title: string
}

const CounterMetric = (props: MetricModel) => {
	return (
		<div className='counter-metrics'>
			<h1>
				<CountUp  start={props.start} end={props.end} enableScrollSpy />+
			</h1>
			<p>{props.title}</p>
		</div>
	)
}

const Featured = () => {
	const { ref: contRef, inView: inCntView} = useInView({
		threshold: 0,
	});
	const { ref: certRef, inView: inCertView} = useInView({
		threshold: 1,
	});
	return (
		<div className='ft-component'>

			<div className="metric-header">
				<div className={`num-ft ${inCntView ? "trans-from-left" : ""} `} ref={contRef}>
					<CounterMetric start={10} end={74} title={"Projects"}/>
				</div>
				<div className="num-ft">
					<CounterMetric start={2} end={10} title={"Blogs"} />
				</div>
				<div className={`num-ft ${inCntView ? "trans-from-right" : ""} `} ref={contRef}>
					<CounterMetric start={6000} end={6897} title={"Profile Views"} />
				</div>
			</div>

			{/* Certification */}

			{/* <div className="certification">
				<div>
					<h1 className={`${inCertView ? "": ""}`} ref={certRef}>Certified</h1>
					<div className="badges">
						<img src={AWS_SAA} className={`badge-saa ${inCertView ? "trans-from-left": ""}`} alt="" ref={certRef} />
						<img src={CKA} className={`badge-cka ${inCertView ? "trans-from-buttom": ""}`} alt="" ref={certRef} />
						<img src={CKAD} className={`badge-ckad ${inCertView ? "trans-from-right": ""}`} alt="" ref={certRef} />
					</div>
				</div>
			</div> */}

		</div>
	)
}

export default Featured