import React from 'react';

// Internals
import './highlights.styles.css';

const Highlights = (props: { title: string, url: string, target: string }) => {
	return (
		<div className="containers"
			style={{
				backgroundImage: `url(${props?.url})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover'
			}}
		>
			<div className="ft-content">
				<a href={props.target} target='__blank'> ↖︎ Explore more</a>
			</div>
		</div>
	)
}

export default Highlights