import React from 'react'

// Internals
import Hackathon from './hackathon.components';
import './hackathon.styles.css';

let Hackathons = [
  {
      color: "#ecf8f4",
      image: "https://d112y698adiu2z.cloudfront.net/photos/production/challenge_thumbnails/002/001/354/datas/medium.png",
      title: "Coastal Hacks",
      font_color: "#000000"
  },
  {
      color: "#1c1c1e",
      image: "https://d112y698adiu2z.cloudfront.net/photos/production/challenge_thumbnails/002/064/961/datas/medium.png",
      title: "Wild Hacks II",
      font_color: "white"
  },
  {
      color: "#f7bb24",
      image: "https://d112y698adiu2z.cloudfront.net/photos/production/challenge_thumbnails/002/192/067/datas/medium.png",
      title: "A Hack A Day 2",
      font_color: "#000000"
  },
  {
      color: "#f4ecf3",
      image: "https://d112y698adiu2z.cloudfront.net/photos/production/challenge_thumbnails/002/006/140/datas/medium.png",
      title: "Mental Health Hacks II",
      font_color: "#000000"
  },
  {
      color: "#cfe6ea",
      image: "https://d112y698adiu2z.cloudfront.net/photos/production/challenge_thumbnails/001/991/262/datas/medium.png",
      title: "StepZen GraphQL Challenge",
      font_color: "#000000"
  },
]

const Hacks = () => {
  return (
    <div className="hack-container">
        <div className='hack-list'>
          {Hackathons.map((hack: { color: string, image: string, font_color: string, title: string }) => (
            <>
              <Hackathon 
                color={hack.color} 
                image={hack.image} 
                title={hack.title} 
                font_color={hack.font_color} 
              />
            </>
          ))}
        </div>
    </div>
  )
}

export default Hacks