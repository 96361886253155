import React from 'react'
import { useInView } from 'react-intersection-observer';

// Internals
import './hackathon.styles.css';

const Hackathon = (props: { color: string, image: string, font_color: string, title: string }) => {
	const { ref: hackathonRef, inView: hackathonView } = useInView({ threshold: 0, });

  return (
    <div className={`hack-card ${hackathonView ? "scale-in-center": ""}`} 
    style={{ 
      backgroundColor: props.color,
       }} 
    ref={hackathonRef}
      >
        <img src={props.image} className="hack-icon" alt="hack-img" />
        <div className="hack-content">
            <h2 style={{
              color: props.font_color,
            }}>{props.title}</h2>
        </div>
    </div>
  )
}

export default Hackathon